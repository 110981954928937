import { PlusOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Card,
  DatePickerProps,
  Divider,
  Form,
  FormProps,
  Input,
  Popconfirm,
  Select
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { getOrganization, getUsers, GetUsersResponse, useFetch } from 'api';
import { batchCreateBookmarks } from 'api/bookmarks';
import { CardHeader } from 'components/cards';
import { InputForm } from 'components/forms';
import { DataTable } from 'components/tables';
import { useState } from 'react';
import { useParams } from 'react-router';
import { Link, useNavigate, useSearchParams } from 'react-router-dom-v5-compat';
import { useUpdateEffect } from 'usehooks-ts';
import * as paths from 'utils/paths';

import { BookmarkAssignmentForm } from '../../components/forms/bookmark_assignment_form';

const { Item } = Form;

export const BookmarkOrgAssignmentPage = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [searchParams] = useSearchParams();
  const { data } = useFetch(getOrganization, [id]);
  const [form] = useForm();

  const [userSelection, setUserSelection] = useState<string>(
    searchParams.get('emails[]') !== null ? 'select' : 'all'
  );
  const [emailSearchList, setEmailSearchList] = useState<string[]>(
    searchParams.get('emails[]') !== null ? searchParams.get('emails[]')?.split(',')! : []
  );
  const [emailSearchInput, setEmailSearchInput] = useState<string>('');
  const [userList, setUserList] = useState<GetUsersResponse[]>([]);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [noMatch, setNoMatch] = useState<string[]>([]);

  const escapeRegExp = (string: string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

  const handleAddUserByEmail = () => {
    if (emailSearchInput === '') return;
    const multiEmails = emailSearchInput.split(',').map(e => e.trim());
    const emails = [...emailSearchList, ...multiEmails];
    setEmailSearchList(emails);
    setEmailSearchInput('');
  };

  const onFinish: FormProps<{
    bookmarks?: {
      email_send_date?: DatePickerProps['value'];
      end_date?: DatePickerProps['value'];
      resource_id: string;
      resource_type: 'Course' | 'CourseCollection';
      start_date?: DatePickerProps['value'];
    }[];
  }>['onFinish'] = ({ bookmarks }) => {
    if (userSelection === 'all') {
      if (bookmarks && bookmarks.length > 0) {
        batchCreateBookmarks({
          bookmarks: bookmarks.map(bookmark => ({
            ...bookmark,
            assigned_by_id: id,
            assigned_by_type: 'Organization'
          }))
        }).then(() => navigate(paths.organizationPath(id)));
      }
    } else {
      if (userList.length > 0 && bookmarks && bookmarks.length > 0) {
        batchCreateBookmarks({
          bookmarks: userList
            .map(user =>
              bookmarks.map(bookmark => ({
                ...bookmark,
                assigned_by_id: id,
                assigned_by_type: 'Organization' as const,
                user_id: user.id
              }))
            )
            .flat()
        }).then(() => navigate(paths.organizationPath(id)));
      }
    }
  };

  useUpdateEffect(() => {
    if (isLoadingUsers) return;
    setNoMatch(
      emailSearchList.filter(email => {
        const likePattern = `^.*${escapeRegExp(email.trim().toLowerCase())}.*$`;
        const regexp = new RegExp(likePattern, 'i');
        return userList.some(user => regexp.test(user.email.toLowerCase())) ? null : email;
      })
    );
  }, [userList, isLoadingUsers]);

  return (
    <Card
      title={
        <CardHeader
          title={
            <>
              Assign education to <Link to={paths.organizationPath(id)}>{data?.data?.name}</Link>
            </>
          }
        />
      }
    >
      <InputForm
        form={form}
        name="assign-bookmark"
        onFinish={onFinish}
        submitIsDisabled={userSelection === 'select' && userList.length === 0}
      >
        <Item label="Educations">
          <Form.List name="bookmarks">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name }) => (
                  <div key={key}>
                    <BookmarkAssignmentForm
                      assignedById={id}
                      name={name}
                      page="create"
                      parentForm={form}
                      remove={remove}
                    />

                    <Divider />
                  </div>
                ))}

                <Item>
                  <Button
                    block
                    icon={<PlusOutlined />}
                    onClick={() => add()}
                    type="dashed"
                  >
                    Add Resource
                  </Button>
                </Item>
              </>
            )}
          </Form.List>
        </Item>

        <Item label="Assign to">
          <Select
            onChange={setUserSelection}
            value={userSelection}
          >
            <Select.Option value="all">All users</Select.Option>
            <Select.Option value="select">Select users</Select.Option>
          </Select>
        </Item>

        {userSelection === 'select' && (
          <>
            <Item label="Select users">
              <div style={{ display: 'flex' }}>
                <Input
                  onChange={event => setEmailSearchInput(event.target.value)}
                  placeholder="Search by email"
                  style={{ marginRight: '1rem' }}
                  value={emailSearchInput}
                />
                <Button
                  onClick={handleAddUserByEmail}
                  type="primary"
                >
                  Add
                </Button>
              </div>
            </Item>
            {noMatch.length > 0 && (
              <Item label="Errors:">
                {noMatch.map(email => (
                  <Alert
                    action={
                      <Button
                        danger
                        onClick={() => {
                          setEmailSearchList(emailSearchList.filter(e => e !== email));
                          setNoMatch(noMatch.filter(e => e !== email));
                        }}
                        size="small"
                      >
                        OK
                      </Button>
                    }
                    message={
                      <>
                        <strong>{email}</strong> was not found for this organization or is
                        deactivated.
                      </>
                    }
                    showIcon
                    style={{ marginBottom: '1rem' }}
                    type="error"
                  />
                ))}
              </Item>
            )}
            {emailSearchList.length > 0 && (
              <DataTable
                columns={[
                  {
                    dataIndex: 'first_name',
                    key: 'first_name',
                    render: (
                      first_name: GetUsersResponse['first_name'],
                      record: GetUsersResponse
                    ) => `${first_name} ${record.last_name}`,
                    title: 'Name'
                  },
                  {
                    dataIndex: 'email',
                    key: 'email',
                    title: 'Email'
                  },
                  {
                    key: 'action',
                    render: () => (
                      <Popconfirm
                        cancelText="Cancel"
                        okText="Yes"
                        onConfirm={() => {
                          const emails = emailSearchList.slice(0, emailSearchList.length - 1);
                          setEmailSearchList(emails);
                        }}
                        title="Are you sure you want to remove this email?"
                      >
                        <Button
                          danger
                          size="small"
                        >
                          Remove
                        </Button>
                      </Popconfirm>
                    ),
                    title: 'Actions'
                  }
                ]}
                getMethod={getUsers}
                getParams={{
                  'emails[]': emailSearchList.map(email => encodeURI(email)),
                  organization_id: id,
                  per_page: '50',
                  status: 'activated'
                }}
                pagination={false}
                updateDataUpstream={setUserList}
                updateIsLoadingUpstream={setIsLoadingUsers}
              />
            )}
          </>
        )}
      </InputForm>
    </Card>
  );
};
