// import type { AxiosResponse } from 'axios';

import { instance } from '.';

/*
 TODO: Uncomment if we ever want to add a single organization membership

interface CreateOrganizationMembershipParams {
  organization_membership: {
    organization_id: string;
    organization_license_tier_id: string;
    status: 'activated' | 'deactivated';
    user_id: string;
  };
}

export const createOrganizationMembership = (params: CreateOrganizationMembershipParams) =>
  instance.post<'', AxiosResponse<''>, CreateOrganizationMembershipParams>(
    '/admin/organization_memberships',
    params
  );
*/

export const deleteOrganizationMembership = (id: string) =>
  instance.delete<''>(`/admin/organization_memberships/${id}`);
