import {
  CheckCircleFilled,
  CloseCircleFilled,
  DeleteFilled,
  PlusOutlined
} from '@ant-design/icons';
import { Card, Popconfirm, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { deletePathway, GetPathwayResponse, getPathways, updatePathway } from 'api';
import { StyledTitle } from 'components/cards';
import { DateFormatter } from 'components/date_formatter';
import { ActionsColumn, DataTable } from 'components/tables';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { toFormData } from 'utils/forms';
import * as paths from 'utils/paths';

const UnstyledButtonStyles = `
  cursor: pointer;
  appearance: none;
  display: flex;
  gap: 0.25rem;
  background: none;
  padding: 0;
  border: none;
  margin: 0;
  width: 100%;
`;

const UnstyledButton = styled.button<{ $danger: boolean }>`
  ${UnstyledButtonStyles}
  ${({ $danger }) =>
    $danger
      ? 'color: inherit;'
      : `color: #6abe39;
    margin: -5px -12px;
    border-radius: 0.25rem;
    padding: 5px 12px;
    width: calc(100% + 24px);

    &:hover {
      color: white;
      background: #6abe39;
    }
    `}
`;

const columns: ColumnsType<GetPathwayResponse> = [
  {
    dataIndex: 'name',
    key: 'name',
    render: (name: GetPathwayResponse['name'], record: GetPathwayResponse) => (
      <Link to={paths.pathwayPath(record.id)}>{name}</Link>
    ),
    title: <h1> Name </h1>
  },
  {
    dataIndex: 'published',
    key: 'published',
    render: (published: GetPathwayResponse['published']) => (
      <Tag color={published ? 'green' : 'red'}>{published ? 'yes' : 'no'}</Tag>
    ),
    title: <h1> Published </h1>
  },
  {
    dataIndex: 'hours',
    key: 'hours',
    render: (hours: GetPathwayResponse['hours']) => (
      <div>
        {Number(hours)} hour{Number(hours) > 1 ? 's' : ''}
      </div>
    ),
    title: <h1> Length </h1>
  },
  {
    dataIndex: 'specialty_area',
    key: 'specialty_area',
    render: (specialty_area: GetPathwayResponse['specialty_area']) => (
      <div>{specialty_area.join(', ')}</div>
    ),
    title: <h1> Speciality area </h1>
  },
  {
    dataIndex: 'level',
    key: 'level',
    title: <h1> Level </h1>
  },
  {
    dataIndex: 'created_at',
    key: 'created_at',
    render: (created_at: GetPathwayResponse['created_at']) => (
      <div>
        <DateFormatter
          format="MM/DD/YYYY"
          hideTimezone
          value={created_at}
        />
      </div>
    ),
    title: <h1> Created at </h1>
  },
  {
    dataIndex: 'updated_at',
    key: 'updated_at',
    render: (updated_at: GetPathwayResponse['updated_at']) => (
      <div>
        <DateFormatter
          format="MM/DD/YYYY"
          hideTimezone
          value={updated_at}
        />
      </div>
    ),
    title: <h1> Updated at </h1>
  },
  {
    render: (_value, record) => (
      <ActionsColumn
        customItems={[
          {
            danger: record.published,
            key: 'publish',
            label: (
              <Popconfirm
                cancelText="No"
                okText="Yes"
                onConfirm={() => {
                  updatePathway(
                    record.id,
                    toFormData({ pathway: { published: !record.published } })
                  ).finally(() => {
                    window.location.reload();
                  });
                }}
                title={
                  <>
                    Are you sure you want to{' '}
                    <strong>{record.published ? 'unpublish' : 'publish'}</strong> pathway{' '}
                    <strong>{record.name}</strong>?
                  </>
                }
              >
                <UnstyledButton $danger={record.published}>
                  {record.published ? <CloseCircleFilled /> : <CheckCircleFilled />}
                  {record.published ? 'Unpublish' : 'Publish'}
                </UnstyledButton>
              </Popconfirm>
            )
          },
          {
            danger: true,
            key: 'delete',
            label: (
              <Popconfirm
                cancelText="No"
                okText="Yes"
                onConfirm={() => {
                  deletePathway(record.id).finally(() => {
                    window.location.reload();
                  });
                }}
                title={
                  <>
                    Are you sure you want to <strong>delete</strong> pathway{' '}
                    <strong>{record.name}</strong>?
                  </>
                }
              >
                <UnstyledButton $danger>
                  <DeleteFilled />
                  Delete
                </UnstyledButton>
              </Popconfirm>
            )
          }
        ]}
        editPath={paths.pathwayEditPath(record.id.toString())}
        record={record}
        showPath={paths.pathwayPath(record.id.toString())}
      />
    ),
    title: <h1> Actions </h1>
  }
];

export const PathwaysPage = () => (
  <Card
    bordered={false}
    title={
      <StyledTitle
        buttonsContents={[
          <Link
            key="2"
            to={paths.pathwayCreatePath}
          >
            <PlusOutlined /> Create pathway
          </Link>
        ]}
        title="Pathways"
      />
    }
  >
    <DataTable
      columns={columns}
      filters={[
        {
          key: 'published',
          label: 'Published',
          options: [
            { text: '', value: undefined },
            { text: 'Yes', value: true },
            { text: 'No', value: false }
          ]
        },
        {
          key: 'name',
          label: 'Name'
        }
      ]}
      getMethod={getPathways}
      perPage={20}
    />
  </Card>
);
