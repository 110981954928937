import { Button, Form, FormProps, Input, Select, SelectProps, Space } from 'antd';
import { SearchInputItem } from 'components/forms';
import React from 'react';

export interface FilterType<T> {
  allowClear?: boolean;
  defaultValue?: SelectProps<boolean>['defaultValue'];
  key: string;
  label: string;
  multiple?: boolean;
  options?: { text: string; value: boolean | string | null | undefined }[];
  searchProps?: {
    getMethod: (
      page?: string,
      pageSize?: string,
      getParams?: object
    ) => Promise<ApiResponse<T[], IndexPageMeta>>;
    getMethodArgs: [number, number];
    paramsMethod: (obj: T) => { name: string; value: string };
  };
}

interface TableFiltersProps<T> {
  defaultFilters?: Record<string, string[] | boolean | string | null | undefined>;
  filters: FilterType<T>[];
  setFilterData: React.Dispatch<
    React.SetStateAction<Record<string, string[] | boolean | string | null | undefined>>
  >;
}

const filterToItem = <T,>(filter: FilterType<T>) => {
  const { allowClear, defaultValue, key, label, multiple = false, options, searchProps } = filter;

  if (searchProps) {
    const { getMethod, getMethodArgs, paramsMethod } = searchProps;
    return (
      <SearchInputItem
        key={filter.key}
        getMethod={getMethod}
        getMethodArgs={getMethodArgs}
        label={label}
        name={[filter.key]}
        paramsMethod={paramsMethod}
        style={{ margin: '5px' }}
      />
    );
  }

  return (
    <Form.Item
      key={key}
      label={label}
      name={key}
      style={{ margin: '5px' }}
    >
      {options ? (
        <Select
          allowClear={allowClear}
          defaultValue={defaultValue}
          mode={multiple ? 'multiple' : undefined}
          style={{ width: multiple ? '200px' : '100px' }}
        >
          {options.map(({ text, value }, index) => (
            <React.Fragment key={index}>
              <Select.Option value={value}>{text}</Select.Option>
            </React.Fragment>
          ))}
        </Select>
      ) : (
        <Input
          allowClear={allowClear}
          style={{ width: '150px' }}
        />
      )}
    </Form.Item>
  );
};

export const TableFilters = <T,>({
  defaultFilters,
  filters,
  setFilterData
}: TableFiltersProps<T>) => {
  const [form] = Form.useForm();

  const onFinish: FormProps<
    Record<string, string[] | boolean | string | null | undefined>
  >['onFinish'] = values => {
    setFilterData(values);
  };

  const onReset = () => {
    form.resetFields();
    setFilterData(defaultFilters ? defaultFilters : {});
  };

  return (
    <Form
      form={form}
      initialValues={defaultFilters}
      layout="inline"
      onFinish={onFinish}
      style={{ width: '100%' }}
    >
      {filters.map(filter => filterToItem<T>(filter))}
      <Form.Item>
        <div style={{ margin: '5px' }}>
          <Space style={{ width: '100%' }}>
            <Button
              htmlType="submit"
              size="middle"
              type="primary"
            >
              Apply filters
            </Button>
            <Button
              danger
              htmlType="button"
              onClick={onReset}
              size="middle"
              type="primary"
            >
              Clear
            </Button>
          </Space>
        </div>
      </Form.Item>
    </Form>
  );
};
