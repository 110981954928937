import { Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { GetCourseCollectionsResponse } from 'api';
import { Link } from 'react-router-dom';
import { courseCollectionPath } from 'utils/paths';

interface CollectionPublishedTagProps {
  publishedStatus: string;
}
export const CollectionPublishedTag = ({ publishedStatus }: CollectionPublishedTagProps) => {
  let color = 'gray';
  if (publishedStatus === 'published') color = 'green';
  if (publishedStatus === 'pinned') color = 'gold';
  return <Tag color={color}>{publishedStatus}</Tag>;
};

export const courseCollectionColumns: ColumnsType<GetCourseCollectionsResponse> = [
  {
    dataIndex: 'name',
    key: 'name',
    render: (name: GetCourseCollectionsResponse['name'], record) => (
      <Link to={courseCollectionPath(record.id)}>{name}</Link>
    ),
    title: <h1> Collection name </h1>
  },
  {
    dataIndex: 'hours',
    key: 'hours',
    title: <h1> Hours </h1>
  },
  {
    dataIndex: 'external_accreditation_references',
    key: 'external_accreditation_references',
    render: (
      external_accreditation_references: GetCourseCollectionsResponse['external_accreditation_references']
    ) => external_accreditation_references.map(ref => <div>{ref.vendor_name}</div>),
    title: <h1> Accreditation(s) </h1>
  },
  {
    dataIndex: 'published',
    key: 'published',
    render: (published: GetCourseCollectionsResponse['published']) => (
      <CollectionPublishedTag publishedStatus={published} />
    ),
    title: <h1> Status </h1>
  },
  {
    dataIndex: 'communities',
    key: 'communities',
    render: (communities: GetCourseCollectionsResponse['communities']) => {
      const colorList = {
        bipoc: 'blue',
        lgbq: 'purple',
        tgnc: 'pink'
      };
      const tagList = communities.map(community => (
        <Tag color={colorList[community]}>{community}</Tag>
      ));
      return tagList;
    },
    title: 'Communities'
  }
];
