import { instance } from '.';

export interface GetNetworkMembersResponse {
  created_at: string;
  direct_contract: boolean;
  id: string;
  name: string;
  user_totals: {
    activated: number;
    created: number;
    deactivated: number;
    inferred: number;
    onboarded: number;
    total: number;
  };
}

export const getNetworkMembers = (page?: string, pageSize?: string, getParams?: object) =>
  instance
    .get<ApiResponse<GetNetworkMembersResponse[], IndexPageMeta>>(
      '/admin/networks/organization_members',
      {
        params: {
          ...getParams,
          page,
          per_page: pageSize
        }
      }
    )
    .then(res => res.data);
