import {
  UserCreatePage,
  UserEditPage,
  UserPage,
  UserScoringPage,
  UsersImportCsvPage,
  UsersPage
} from 'pages/users';
import { RouteConfig } from 'react-router-config';
import * as paths from 'utils/paths';

export const userRoutes: RouteConfig[] = [
  {
    breadcrumb: 'Users',
    component: UsersPage,
    exact: true,
    path: paths.usersPath
  },
  {
    breadcrumb: 'Create',
    component: UserCreatePage,
    path: paths.userCreatePath
  },
  {
    breadcrumb: 'Import',
    component: UsersImportCsvPage,
    path: paths.usersImportCsvPath
  },
  {
    component: UserPage,
    exact: true,
    path: paths.userPath(':id')
  },
  {
    component: UserEditPage,
    path: paths.userEditPath(':id')
  },
  {
    component: UserScoringPage,
    path: paths.userScoringPath(':id')
  }
];
