/* eslint-disable jsx-a11y/anchor-is-valid */
import { MailTwoTone } from '@ant-design/icons';
import { Popconfirm, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { deleteBookmark, GetBookmarksResponse } from 'api';
import { DateFormatter } from 'components/date_formatter';
import { ActionsColumn } from 'components/tables';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

import {
  bookmarkNetworkAssignmentEditPath,
  bookmarkOrgAssignmentEditPath,
  courseCollectionEditPath,
  coursePath,
  userPath
} from '../utils/paths';

export const bookmarksColumns: ColumnsType<GetBookmarksResponse> = [
  {
    key: 'resource',
    render: (_value, bookmark) =>
      'name' in bookmark.resource ? (
        <Link to={courseCollectionEditPath(bookmark.resource_id)}>{bookmark.resource.name}</Link>
      ) : (
        <Link to={coursePath(bookmark.resource_id)}>{bookmark.resource.short_title}</Link>
      ),
    title: <h1> Resource </h1>
  },
  {
    dataIndex: 'resource_type',
    key: 'resource_type',
    title: <h1> Resource type </h1>
  },
  {
    dataIndex: 'start_date',
    key: 'start_date',
    render: (value: GetBookmarksResponse['start_date'], record) => {
      const isPast = dayjs().isAfter(dayjs(record.email_send_date ?? record.start_date));
      return (
        <>
          <DateFormatter value={value!} />
          {record.email_send_date !== null && (
            <Tooltip
              title={`Email ${isPast ? 'sent' : 'scheduled'} ${dayjs(record.email_send_date).format(
                'MM/DD/YYYY HH:mm z'
              )}`}
            >
              <MailTwoTone
                style={{ marginLeft: '0.25rem' }}
                twoToneColor="#999"
              />
            </Tooltip>
          )}
        </>
      );
    },
    title: <h1> Start date </h1>
  },
  {
    dataIndex: 'end_date',
    key: 'end_date',
    render: (value: GetBookmarksResponse['end_date']) => <DateFormatter value={value!} />,
    title: <h1> End date </h1>
  },
  {
    dataIndex: 'user_name',
    key: 'user_name',
    render: (value: GetBookmarksResponse['user_name'], record: GetBookmarksResponse) =>
      value !== null && record.user_id !== null ? (
        <Link to={userPath(record.user_id)}>{value}</Link>
      ) : (
        'All users'
      ),
    title: <h1>Assigned to</h1>
  },
  {
    key: 'actions',
    render: (value: GetBookmarksResponse, record) => (
      <ActionsColumn
        customItems={[
          {
            key: 'edit',
            label: (
              <Link
                to={
                  value.assigned_by_type === 'Organization'
                    ? bookmarkOrgAssignmentEditPath(value.assigned_by_id, value.id)
                    : bookmarkNetworkAssignmentEditPath(value.assigned_by_id, record.id)
                }
              >
                Edit
              </Link>
            )
          },
          {
            danger: true,
            key: 'delete',
            label: (
              <Popconfirm
                cancelText="No"
                okText="Yes"
                onConfirm={() => {
                  deleteBookmark(record.id);
                }}
                title="Are you sure you want to delete this record?"
              >
                <a>Delete</a>
              </Popconfirm>
            )
          }
        ]}
        record={record}
      />
    ),
    title: <h1> Actions </h1>
  }
];
