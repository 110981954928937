import { Button, Col, Row, Space } from 'antd';
import { ReactNode } from 'react';

interface StyledTitleProps {
  buttonsContents?: ReactNode | ReactNode[];
  title: JSX.Element | string;
}

export const StyledTitle = ({ buttonsContents, title }: StyledTitleProps) => (
  <Row style={{ height: '70px', width: '100%' }}>
    <Col
      flex="1"
      style={{
        alignItems: 'center',
        display: 'flex',
        paddingLeft: 15
      }}
    >
      <div style={{ color: '#757575', fontSize: 'xx-large' }}>{title}</div>{' '}
    </Col>

    {buttonsContents !== undefined && (
      <Space>
        {(Array.isArray(buttonsContents) ? buttonsContents : [buttonsContents]).map(
          (content, index) => (
            <Col
              key={index}
              flex="0.08"
            >
              <Button
                size="large"
                type="primary"
              >
                {content}
              </Button>
            </Col>
          )
        )}
      </Space>
    )}
  </Row>
);
