import { PlusOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { getOrganizations, GetOrganizationsResponse } from 'api';
import { StyledTitle } from 'components/cards';
import { ActionsColumn, DataTable } from 'components/tables';
import { Link } from 'react-router-dom-v5-compat';
import * as paths from 'utils/paths';

const columns: ColumnsType<GetOrganizationsResponse> = [
  {
    dataIndex: 'name',
    key: 'name',
    render: (value: GetOrganizationsResponse['name'], record) => (
      <Link to={paths.organizationPath(record.id)}>{value}</Link>
    ),
    title: <h1> Organization Name </h1>
  },
  {
    dataIndex: 'invited_users',
    key: 'invited_users',
    title: <h1> Invited Users </h1>
  },
  {
    dataIndex: 'accepted_count',
    key: 'accepted_count',
    title: <h1> Active Users </h1>
  },
  {
    dataIndex: 'completed_onboarding',
    key: 'completed_onboarding',
    title: <h1> Onboarded Users </h1>
  },
  {
    key: 'onboarded_percentage',
    render: (_value, record) => {
      if (record.invited_users === 0) return '-';
      return `${Math.round((record.completed_onboarding / record.invited_users) * 100)}%`;
    },
    title: <h1> Onboarded % </h1>
  },
  {
    render: (_value, record) => (
      <ActionsColumn
        editPath={paths.organizationEditPath(record.id.toString())}
        record={record}
        showPath={paths.organizationPath(record.id.toString())}
      />
    ),
    title: <h1> Actions </h1>
  }
];

export const OrganizationsPage = () => (
  <Card
    bordered={false}
    title={
      <StyledTitle
        buttonsContents={
          <Link
            key="2"
            to={paths.organizationCreatePath}
          >
            <PlusOutlined /> Create Organization
          </Link>
        }
        title="Organizations"
      />
    }
  >
    <DataTable
      columns={columns}
      filters={[
        {
          key: 'name',
          label: 'Name'
        },
        {
          key: 'network',
          label: 'Network org',
          options: [
            { text: '', value: undefined },
            { text: 'yes', value: true },
            { text: 'no', value: false }
          ]
        },

        {
          defaultValue: true,
          key: 'direct_contract',
          label: 'Direct contract',
          options: [
            { text: 'yes', value: true },
            { text: 'no', value: false }
          ]
        }
      ]}
      getMethod={getOrganizations}
      perPage={15}
    />
  </Card>
);
