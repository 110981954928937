import type { AxiosResponse } from 'axios';
import type { RequireAtLeastOne } from 'type-fest';

import { instance } from '.';

interface CreateOrganizationParams {
  organization: {
    hubspot_id: string;
    name: string;
    network?: boolean;
    organization_email_domains_attributes?: {
      domain: string;
    }[];
    organization_license_tiers_attributes?: {
      license_tier_id: string;
    }[];
    referral_code: string | null;
    sso_connection_id?: string;
    unlimited_license?: boolean;
  };
}

export interface BulkUserRemovalResponse {
  data: {
    deactivated_count: number;
    errors: RemovalError[];
    failed_count: number;
    not_found_count: number;
    removed_count: number;
  };
  info: string[];
  status: string;
}

export interface CreateOrganizationResponse {
  direct_contract: boolean;
  enforced_settings: [];
  hubspot_id: number;
  id: string;
  name: string;
  network: boolean;
  permissions: OrganizationPermission[];
  referral_code: string | null;
  sso_connection_id: string | null;
  sso_connection_name: string | null;
  sso_sign_in_url: string | null;
  unlimited_license: boolean;
}

export interface GetOrganizationResponse {
  average_course_score: number | null;
  communication_infos: {
    clicked: number;
    delivered: number;
    email_type: string;
    opened: number;
    sent: number;
  }[];
  detailed_overview: {
    active_count: number;
    completed_onboarding: number;
    invite_date: string;
    invited_users: number;
    requires_assessment: number;
  }[];
  direct_contract: boolean;
  education_completed_count: number;
  education_hours: string;
  enforced_settings: [];
  hubspot_id: number;
  id: string;
  managed_networks: {
    created_at: string;
    id: string;
    name: string;
    total_organization_members: number;
  }[];
  name: string;
  network: boolean;
  network_member_organizations: {
    created_at: string;
    id: string;
    name: string;
  }[];
  organization_email_domains: {
    domain: string;
    id: string;
  }[];
  organization_license_tiers: {
    available_licenses: number;
    id: string;
    license_tier_id: string;
    total_licenses: number;
  }[];
  permissions: OrganizationPermission[];
  referral_code: string | null;
  sso_connection_id: string | null;
  sso_connection_name: string | null;
  sso_sign_in_url: string | null;
  unlimited_license: boolean;
  user_totals: {
    activated: number;
    created: number;
    deactivated: number;
    inferred: number;
    onboarded: number;
    total: number;
  };
}

interface UpdateOrganizationParams {
  organization: RequireAtLeastOne<{
    hubspot_id: number;
    name: string;
    network: boolean;
    organization_email_domains_attributes: (
      | {
          _destroy: true;
          id: string;
        }
      | {
          domain: string;
          id: string;
        }
    )[];
    organization_license_tiers_attributes: (
      | {
          _destroy: true;
          id: string;
        }
      | {
          available_licenses: number;
          id: string;
          license_tier_id: string;
          total_licenses: number;
        }
    )[];
    referral_code: string | null;
    sso_connection_id: string | null;
    unlimited_license: boolean;
  }>;
}

interface UpdateOrganizationResponse {
  direct_contract: boolean;
  enforced_settings: [];
  hubspot_id: number;
  id: string;
  name: string;
  network: boolean;
  permissions: OrganizationPermission[];
  referral_code: string | null;
  sso_connection_id: string | null;
  sso_connection_name: string | null;
  sso_sign_in_url: string | null;
  unlimited_license: boolean;
}

interface TransferOrganizationMembershipsParams {
  from_org: string;
  to_org: string;
}

interface TransferOrganizationMembershipsResponse {
  users_migrated: number;
}

interface RemovalError {
  message: string;
  type: 'error' | 'info';
  user_id: string;
}

export interface GetOrganizationsResponse {
  accepted_count: number;
  completed_onboarding: number;
  direct_contract: boolean;
  enforced_settings: [];
  hubspot_id: number;
  id: string;
  invited_users: number;
  name: string;
  network: boolean;
  permissions: OrganizationPermission[];
  sso_connection_id: string | null;
  sso_connection_name: string | null;
  sso_sign_in_url: string | null;
  unlimited_license: boolean;
}

export const bulkOrganizationRemovalViaCsvFile = (id: string, params: FormData) =>
  instance
    .post<BulkUserRemovalResponse>(`/admin/organizations/${id}/bulk_membership_removal`, params)
    .then(res => res.data);

export const createOrganization = (params: CreateOrganizationParams) =>
  instance
    .post<
      ApiResponse<CreateOrganizationResponse>,
      AxiosResponse<CreateOrganizationResponse>,
      CreateOrganizationParams
    >('/admin/organizations', params)
    .then(res => res);

export const getOrganization = (id: string, page?: string, pageSize?: string) =>
  instance
    .get<ApiResponse<GetOrganizationResponse>>(`/admin/organizations/${id}`, {
      params: {
        page,
        per_page: pageSize
      }
    })
    .then(res => res.data);

export const getOrganizations = (page?: string, pageSize?: string, getParams?: object) =>
  instance
    .get<ApiResponse<GetOrganizationsResponse[], IndexPageMeta>>('/admin/organizations', {
      params: {
        ...getParams,
        page,
        per_page: pageSize
      }
    })
    .then(res => res.data);

export const transferOrganizationMemberships = (params: TransferOrganizationMembershipsParams) =>
  instance
    .post<
      ApiResponse<TransferOrganizationMembershipsResponse>,
      AxiosResponse<ApiResponse<TransferOrganizationMembershipsResponse>>,
      TransferOrganizationMembershipsParams
    >('/admin/organizations/transfer', params)
    .then(res => res.data);

export const updateOrganization = (id: string, params: UpdateOrganizationParams) =>
  instance
    .patch<
      ApiResponse<UpdateOrganizationResponse>,
      AxiosResponse<UpdateOrganizationResponse>,
      UpdateOrganizationParams
    >(`/admin/organizations/${id}`, params)
    .then(res => res);
