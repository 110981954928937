/* eslint-disable sort-exports/sort-exports */
export const homePath = '';

export const usersPath = `${homePath}/users`;
export const userPath = (id: string) => `${usersPath}/${id}`;
export const userCreatePath = `${usersPath}/create`;
export const userEditPath = (id: string) => `${userPath(id)}/edit`;
export const userScoringPath = (id: string) => `${userPath(id)}/scoring`;
export const usersImportCsvPath = `${usersPath}/import_csv`;

export const licenseTiersPath = `${homePath}/license_tiers`;
export const licenseTierPath = (id: string) => `${licenseTiersPath}/${id}`;
export const licenseTierCreatePath = `${licenseTiersPath}/create`;
export const licenseTierEditPath = (id: string) => `${licenseTierPath(id)}/edit`;

export const organizationsPath = `${homePath}/organizations`;
export const organizationTransferPath = (id?: string) =>
  `${organizationsPath}/transfer${id !== undefined ? `?from_id=${id}` : ''}`;
export const organizationPath = (id: string) => `${organizationsPath}/${id}`;
export const organizationCreatePath = `${organizationsPath}/create`;
export const organizationEditPath = (id: string) => `${organizationPath(id)}/edit`;
export const organizationReportsPath = (id: string) => `${organizationPath(id)}/reports`;
export const licenseCohortCreatePath = (orgId: string) =>
  `${organizationPath(orgId)}/license_cohorts/create`;
export const bookmarkOrgAssignmentPath = (orgId: string, emails?: string) =>
  `${organizationPath(orgId)}/assign_bookmarks/create${
    emails !== undefined ? `?emails[]=${emails}` : ''
  }`;
export const organizationBulkRemovalPath = (orgId: string) =>
  `${organizationPath(orgId)}/bulk_removal`;
export const bookmarkOrgAssignmentEditPath = (orgId: string, id: string) =>
  `${organizationPath(orgId)}/assign_bookmarks/${id}/edit`;

export const networksPath = `${homePath}/networks`;
export const networkPath = (id: string) => `${networksPath}/${id}`;
export const networkCreatePath = `${networksPath}/create`;
export const networkEditPath = (id: string) => `${networkPath(id)}/edit`;
export const bookmarkNetworkAssignmentPath = (networkId: string) =>
  `${networkPath(networkId)}/assign_bookmarks/create`;
export const bookmarkNetworkAssignmentEditPath = (networkId: string, id: string) =>
  `${networkPath(networkId)}/assign_bookmarks/${id}/edit`;

export const mismatchedRegistryDataPath = `${homePath}/npi_registry_data/mismatched`;

export const badgeGroupsPath = `${homePath}/badge_groups`;
export const badgeGroupPath = (id: string) => `${badgeGroupsPath}/${id}`;
export const badgeGroupCreatePath = `${badgeGroupsPath}/create`;

export const communicationInfosPath = `${homePath}/communication_infos`;
export const communicationInfoPath = (id: string) => `${communicationInfosPath}/${id}`;

export const coursesPath = `${homePath}/courses`;
export const coursePath = (id: string) => `${coursesPath}/${id}`;
export const courseCreatePath = `${coursesPath}/create`;
export const courseEditPath = (id: string) => `${coursePath(id)}/edit`;
export const coursesImportCsvPath = `${coursesPath}/import_csv`;

export const courseCollectionsPath = `${homePath}/course_collections`;
export const courseCollectionPath = (id: string) => `${courseCollectionsPath}/${id}`;
export const courseCollectionCreatePath = `${courseCollectionsPath}/create`;
export const courseCollectionEditPath = (id: string) => `${courseCollectionsPath}/${id}/edit`;

export const courseRegistrationsPath = `${homePath}/course_registrations`;
export const courseRegistrationPath = (id: string) => `${courseRegistrationsPath}/${id}`;

export const educationBenchmarkPath = `${homePath}/educations/benchmark`;
export const importEducationAssessmentsPath = `${homePath}/educations/import_assessments`;

export const pathwaysPath = `${homePath}/pathways`;
export const pathwayPath = (id: string) => `${pathwaysPath}/${id}`;
export const pathwayCreatePath = `${pathwaysPath}/create`;
export const pathwayEditPath = (id: string) => `${pathwayPath(id)}/edit`;

export const tagsPath = `${homePath}/tags`;

export const featureFlagsPath = `${homePath}/feature_flags`;
export const featureFlagCreatePath = `${homePath}/feature_flags/create`;
export const featureFlagEditPath = (id: string) => `${featureFlagsPath}/${id}/edit`;
