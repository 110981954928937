import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, FormProps, Select, Spin } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { GetNetworkResponse, getOrganizations, GetOrganizationsResponse } from 'api';
import { InputForm, InputItem } from 'components/forms';
import { useEffect, useState } from 'react';

interface NetworkFormProps {
  initialData?: GetNetworkResponse;
  name: string;
  onFinish: NonNullable<FormProps['onFinish']>;
}

const { Item } = Form;

export const NetworkForm = ({ initialData, name, onFinish }: NetworkFormProps) => {
  const [form] = useForm();
  const [isFetching, setIsFetching] = useState(false);
  const [organizationData, setOrganizationData] = useState<GetOrganizationsResponse[] | undefined>(
    []
  );
  const [networkOrganizationData, setNetworkOrganizationData] = useState<
    GetOrganizationsResponse[] | undefined
  >([]);

  useEffect(() => {
    // Load some data on initial load
    refetchOrganizationData('', '200', 'false');
    refetchOrganizationData('', '200', 'true');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refetchOrganizationData = async (
    searchName: string,
    pageSize = '20',
    isNetwork?: 'false' | 'true'
  ) => {
    if (isFetching) return;
    setIsFetching(true);
    const organizationData = await getOrganizations('1', pageSize, {
      name: searchName ? searchName : undefined,
      network: isNetwork
    });
    if (isNetwork === 'true') {
      setNetworkOrganizationData(organizationData.data);
    } else {
      setOrganizationData(organizationData.data);
    }
    setIsFetching(false);
  };

  return (
    <InputForm
      form={form}
      initialValues={initialData}
      name={name}
      onFinish={onFinish}
    >
      <InputItem
        label="Name"
        name="name"
        required
      />

      <Item label="Organization Managers">
        <Form.List name="organization_managers">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name }) => (
                <div
                  style={{
                    alignItems: 'baseline',
                    display: 'flex',
                    gap: '8px',
                    width: '100%'
                  }}
                >
                  <Form.Item
                    key={key}
                    name={[name, 'id']}
                    style={{
                      width: '100%'
                    }}
                  >
                    <Select
                      allowClear
                      defaultValue={initialData?.organization_managers[key]?.id}
                      filterOption={() => true}
                      notFoundContent={isFetching ? <Spin size="small" /> : undefined}
                      onSearch={name => refetchOrganizationData(name, '20', 'true')}
                      showSearch
                    >
                      {networkOrganizationData?.map(organization => (
                        <Select.Option
                          key={organization.id}
                          value={organization.id}
                        >
                          {organization.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </div>
              ))}

              <Item>
                <Button
                  block
                  icon={<PlusOutlined />}
                  onClick={() => add()}
                  type="dashed"
                >
                  Add Network Manager
                </Button>
              </Item>
            </>
          )}
        </Form.List>
      </Item>

      <Item label="Organization Members">
        <Form.List name="organization_members">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name }) => (
                <div
                  style={{
                    alignItems: 'baseline',
                    display: 'flex',
                    gap: '8px',
                    width: '100%'
                  }}
                >
                  <Form.Item
                    key={key}
                    name={[name, 'id']}
                    style={{
                      width: '100%'
                    }}
                  >
                    <Select
                      allowClear
                      defaultValue={initialData?.organization_members[key]?.id}
                      filterOption={() => true}
                      notFoundContent={isFetching ? <Spin size="small" /> : undefined}
                      onSearch={name => refetchOrganizationData(name, '20', 'false')}
                      showSearch
                    >
                      {organizationData?.map(organization => (
                        <Select.Option
                          key={organization.id}
                          value={organization.id}
                        >
                          {organization.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </div>
              ))}

              <Item>
                <Button
                  block
                  icon={<PlusOutlined />}
                  onClick={() => add()}
                  type="dashed"
                >
                  Add Network Member
                </Button>
              </Item>
            </>
          )}
        </Form.List>
      </Item>
    </InputForm>
  );
};
