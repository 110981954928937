import Icon, {
  ApartmentOutlined,
  AuditOutlined,
  CrownOutlined,
  ExclamationCircleOutlined,
  FlagOutlined,
  ForkOutlined,
  GroupOutlined,
  MailOutlined,
  ReadOutlined,
  SafetyCertificateOutlined,
  ScheduleOutlined,
  TagOutlined,
  TeamOutlined,
  UserOutlined
} from '@ant-design/icons';
import { Menu } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { createSession } from 'api';
import { ReactComponent as SidekiqIcon } from 'assets/sidekiq-icon.svg';
import { ReactComponent as SwaggerIcon } from 'assets/swagger-icon.svg';
import { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Link } from 'react-router-dom-v5-compat';
import {
  badgeGroupsPath,
  communicationInfosPath,
  courseCollectionsPath,
  courseRegistrationsPath,
  coursesPath,
  educationBenchmarkPath,
  featureFlagsPath,
  licenseTiersPath,
  mismatchedRegistryDataPath,
  networksPath,
  organizationsPath,
  pathwaysPath,
  tagsPath,
  usersPath
} from 'utils/paths';

const externalLinkLabel = (url: string, text: string) => (
  <a
    href={url}
    onClick={e => {
      e.preventDefault();
      createSession().then(() =>
        window.location.assign((e.target as Element).getAttribute('href')!)
      );
    }}
    rel="noopener noreferrer"
    target="_blank"
  >
    {text}
  </a>
);

const useMenuKeys = () => {
  const usersMatch = useRouteMatch(usersPath);
  const badgesMatch = useRouteMatch(badgeGroupsPath);
  const comInfosMatch = useRouteMatch(communicationInfosPath);
  const coursesMatch = useRouteMatch(coursesPath);
  const pathwaysMatch = useRouteMatch(pathwaysPath);
  const tagsMatch = useRouteMatch(tagsPath);
  const organizationsMatch = useRouteMatch(organizationsPath);
  const mismatchedRegistryDatumMatch = useRouteMatch(mismatchedRegistryDataPath);
  const courseCollectionsMatch = useRouteMatch(courseCollectionsPath);
  const courseRegistrationsMatch = useRouteMatch(courseRegistrationsPath);
  const educationBenchmarkMatch = useRouteMatch(educationBenchmarkPath);
  const licenseTiersMatch = useRouteMatch(licenseTiersPath);
  const featureFlagsMatch = useRouteMatch(featureFlagsPath);
  const networksMatch = useRouteMatch(networksPath);

  return useMemo(() => {
    const keys = [];
    usersMatch && keys.push(usersPath);
    badgesMatch && keys.push(badgeGroupsPath);
    comInfosMatch && keys.push(communicationInfosPath);
    coursesMatch && keys.push(coursesPath);
    pathwaysMatch && keys.push(pathwaysPath);
    tagsMatch && keys.push(tagsPath);
    organizationsMatch && keys.push(organizationsPath);
    mismatchedRegistryDatumMatch && keys.push(mismatchedRegistryDataPath);
    courseCollectionsMatch && keys.push(courseCollectionsPath);
    courseRegistrationsMatch && keys.push(courseRegistrationsPath);
    educationBenchmarkMatch && keys.push(educationBenchmarkPath);
    licenseTiersMatch && keys.push(licenseTiersPath);
    featureFlagsMatch && keys.push(featureFlagsPath);
    networksMatch && keys.push(networksPath);

    return keys;
  }, [
    usersMatch,
    badgesMatch,
    comInfosMatch,
    coursesMatch,
    organizationsMatch,
    mismatchedRegistryDatumMatch,
    pathwaysMatch,
    courseCollectionsMatch,
    courseRegistrationsMatch,
    tagsMatch,
    educationBenchmarkMatch,
    licenseTiersMatch,
    featureFlagsMatch,
    networksMatch
  ]);
};

const menuItems: ItemType[] = [
  {
    icon: <UserOutlined />,
    key: usersPath,
    label: <Link to={usersPath}>Users</Link>
  },
  {
    icon: <TeamOutlined />,
    key: organizationsPath,
    label: <Link to={organizationsPath}>Organizations</Link>
  },
  {
    icon: <ApartmentOutlined />,
    key: networksPath,
    label: <Link to={networksPath}>Networks</Link>
  },
  {
    icon: <MailOutlined />,
    key: communicationInfosPath,
    label: <Link to={communicationInfosPath}>E-mails info</Link>
  },
  {
    type: 'divider'
  },
  {
    icon: <CrownOutlined />,
    key: licenseTiersPath,
    label: <Link to={licenseTiersPath}>License Tiers</Link>
  },
  {
    icon: <ExclamationCircleOutlined />,
    key: mismatchedRegistryDataPath,
    label: <Link to={mismatchedRegistryDataPath}>NPI Issues</Link>
  },
  {
    icon: <SafetyCertificateOutlined />,
    key: badgeGroupsPath,
    label: <Link to={badgeGroupsPath}>Benchmark thresholds</Link>
  },
  {
    icon: <AuditOutlined />,
    key: educationBenchmarkPath,
    label: <Link to={educationBenchmarkPath}>Education benchmark</Link>
  },
  {
    type: 'divider'
  },
  {
    icon: <ReadOutlined />,
    key: coursesPath,
    label: <Link to={coursesPath}>Courses</Link>
  },
  {
    icon: <GroupOutlined />,
    key: courseCollectionsPath,
    label: <Link to={courseCollectionsPath}>Collections</Link>
  },
  {
    icon: <ForkOutlined />,
    key: pathwaysPath,
    label: <Link to={pathwaysPath}>Pathways</Link>
  },
  {
    icon: <ScheduleOutlined />,
    key: courseRegistrationsPath,
    label: <Link to={courseRegistrationsPath}>Registrations</Link>
  },
  {
    icon: <TagOutlined />,
    key: tagsPath,
    label: <Link to={tagsPath}>Tags</Link>
  },
  {
    type: 'divider'
  },
  {
    icon: <FlagOutlined />,
    key: featureFlagsPath,
    label: <Link to={featureFlagsPath}>Feature flags</Link>
  },
  {
    icon: (
      <Icon
        component={() => (
          <SidekiqIcon
            fill="currentColor"
            height="1em"
            width="1em"
          />
        )}
      />
    ),
    key: 'sidekiq',
    label: externalLinkLabel(`${process.env['REACT_APP_API_BASE_URL']}/api/sidekiq`, 'Sidekiq')
  },
  {
    icon: (
      <Icon
        component={() => (
          <SwaggerIcon
            fill="currentColor"
            height="1em"
            width="1em"
          />
        )}
      />
    ),
    key: 'swagger',
    label: externalLinkLabel(`${process.env['REACT_APP_API_BASE_URL']}/api/swagger`, 'Swagger')
  }
];

export const SideMenu = () => (
  <Menu
    items={menuItems}
    mode="inline"
    selectedKeys={useMenuKeys()}
    style={{ border: 'none' }}
  />
);
