import { UploadOutlined, UserAddOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import { getOrganizations, getUsers } from 'api';
import { StyledTitle } from 'components/cards';
import { DataTable } from 'components/tables';
import { Link } from 'react-router-dom-v5-compat';
import * as paths from 'utils/paths';

import { userColumns } from './user_components';

export const UsersPage = () => (
  <Card
    bordered={false}
    title={
      <StyledTitle
        buttonsContents={[
          <Link
            key="1"
            to={paths.usersImportCsvPath}
          >
            <UploadOutlined /> Import CSV
          </Link>,
          <Link
            key="2"
            to={paths.userCreatePath}
          >
            <UserAddOutlined /> Create user
          </Link>
        ]}
        title="Users"
      />
    }
  >
    <DataTable
      columns={userColumns}
      filters={[
        {
          key: 'first_name',
          label: 'First name'
        },
        {
          key: 'last_name',
          label: 'Last name'
        },
        {
          key: 'email',
          label: 'E-mail'
        },
        {
          key: 'status',
          label: 'Account status',
          options: [
            { text: '', value: undefined },
            { text: 'activated', value: 'activated' },
            { text: 'deactivated', value: 'deactivated' },
            { text: 'created', value: 'created' },
            { text: 'inferred', value: 'inferred' }
          ]
        },
        {
          key: 'completed_onboarding',
          label: 'Onboarded',
          options: [
            { text: '', value: undefined },
            { text: 'yes', value: true },
            { text: 'no', value: false }
          ]
        },
        {
          key: 'organization_id',
          label: 'Organization',
          searchProps: {
            getMethod: getOrganizations,
            getMethodArgs: [1, 20],
            paramsMethod: obj => ({
              name: obj.name,
              value: obj.id
            })
          }
        },
        {
          key: 'npi',
          label: 'NPI'
        }
      ]}
      getMethod={getUsers}
      perPage={15}
    />
  </Card>
);
