import { DeleteOutlined } from '@ant-design/icons';
import {
  DatePicker,
  DatePickerProps,
  Form,
  FormInstance,
  Select,
  Space,
  Spin,
  Switch,
  Tag
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
  getBookmarks,
  GetBookmarksResponse,
  getCourseCollections,
  GetCourseCollectionsResponse,
  getCourses,
  GetCoursesResponse,
  VendorNames
} from 'api';
import { DateFormatter } from 'components/date_formatter';
import { useDebounce } from 'components/debounce';
import { DataTable } from 'components/tables';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useReducer, useState } from 'react';

interface BookmarkFormState {
  fetchMethod?: () =>
    | Promise<ApiResponse<GetCourseCollectionsResponse[], IndexPageMeta>>
    | Promise<ApiResponse<GetCoursesResponse[], IndexPageMeta>>;
  optionsData: { name: string; value: string }[];
  reloading: boolean;
  resourceType?: 'Course' | 'CourseCollection';
}

type BookmarkFormAction =
  | {
      data: GetCourseCollectionsResponse[] | GetCoursesResponse[];
      type: 'LOADED_DATA';
    }
  | {
      resource_type: 'Course' | 'CourseCollection';
      type: 'UPDATE_RESOURCE_TYPE';
    }
  | {
      type: 'SET_NAME_VALUE';
      value?: string;
    };

const bookmarkReducer = (
  state: BookmarkFormState,
  action: BookmarkFormAction
): BookmarkFormState => {
  switch (action.type) {
    case 'UPDATE_RESOURCE_TYPE':
      return {
        fetchMethod: () =>
          action.resource_type === 'Course'
            ? getCourses('1', '20', {})
            : getCourseCollections('1', '20', {}),
        optionsData: [],
        reloading: true,
        resourceType: action.resource_type
      };
    case 'SET_NAME_VALUE':
      return {
        ...state,
        fetchMethod: () =>
          state.resourceType === 'Course'
            ? getCourses('1', '20', { title: action.value })
            : getCourseCollections('1', '20', { name: action.value }),
        optionsData: [],
        reloading: true
      };
    case 'LOADED_DATA':
      return {
        ...state,
        optionsData:
          state.resourceType === 'Course'
            ? action.data.map(course => ({
                name: (course as GetCoursesResponse).short_title,
                value: course.id
              }))
            : action.data.map(collection => ({
                name: (collection as GetCourseCollectionsResponse).name,
                value: collection.id
              })),
        reloading: false
      };
    default:
      return state;
  }
};

export const BookmarkAssignmentForm = ({
  assignedById,
  name,
  page,
  parentForm,
  remove
}: BookmarkAssignmentFormProps) => {
  const [state, dispatch] = useReducer(bookmarkReducer, {
    optionsData: [],
    reloading: false
  });

  const [curlNameValue, setCurNameValue] = useDebounce<string | undefined>('', 500);
  const [resourceId, setResourceId] = useState(
    page === 'create'
      ? parentForm.getFieldsValue().bookmarks[name]?.resource_id
      : parentForm.getFieldsValue().bookmarks?.at(name)?.resource_id
  );

  const initialEmailDate = parentForm.getFieldsValue().bookmarks?.[name]?.email_send_date;
  const [emailDate, setEmailDate] = useState<DatePickerProps['value']>(
    typeof initialEmailDate === 'string' ? dayjs(initialEmailDate) : null
  );
  const [startDate, setStartDate] = useState<DatePickerProps['value']>(
    parentForm.getFieldsValue().bookmarks?.[name]?.start_date
  );
  const initialStartDate = parentForm.getFieldsValue().bookmarks?.[name]?.start_date;
  const disableEmailDate =
    page === 'create'
      ? false
      : initialEmailDate !== null && initialEmailDate !== undefined
      ? dayjs(emailDate as Dayjs | string).isBefore(dayjs())
      : initialStartDate !== null && initialStartDate !== undefined
      ? dayjs(initialStartDate as Dayjs | string).isBefore(dayjs())
      : false;
  const [emailDateIsEnabled, setEmailDateIsEnabled] = useState(true);

  const subBookmarkColumns: ColumnsType<GetBookmarksResponse> = [
    {
      dataIndex: 'is_editing',
      key: 'is_editing',
      render: (_value, record) => {
        const isEditingThisRecord =
          record.id === parentForm.getFieldValue(['bookmarks', name, 'id']);
        return isEditingThisRecord ? (
          <Tag color="green">Actively editing</Tag>
        ) : (
          <Tag color="blue">Other record</Tag>
        );
      },
      title: 'Record relation'
    },
    ...nestedBookmarksColumns
  ];

  useEffect(() => {
    if (!state.resourceType) return;
    dispatch({ type: 'SET_NAME_VALUE', value: curlNameValue });
  }, [curlNameValue, state.resourceType]);

  useEffect(() => {
    const resourceType =
      page === 'create'
        ? parentForm.getFieldsValue().bookmarks[name]?.resource_type
        : parentForm.getFieldsValue().bookmarks?.at(name)?.resource_type;
    if (!state.resourceType && Boolean(resourceType)) {
      state.resourceType = resourceType;
    }
  }, [name, page, parentForm, state]);

  // Refetch options if state says so
  useEffect(() => {
    if (!state.reloading) return;
    if (!state.fetchMethod) return;

    state.fetchMethod().then(result => dispatch({ data: result.data!, type: 'LOADED_DATA' }));
  }, [state, state.reloading]);

  useEffect(() => {
    if (initialEmailDate === undefined && page === 'edit') return;
    setEmailDateIsEnabled(
      page === 'create' ? true : initialEmailDate !== null && initialEmailDate !== undefined
    );
    setEmailDate(initialEmailDate ?? null);
  }, [initialEmailDate, page]);

  useEffect(() => {
    setStartDate(parentForm.getFieldsValue().bookmarks?.[name]?.start_date);
  }, [parentForm, name]);

  const updateResourceIdHandler = (newResourceId: string) => {
    setResourceId(newResourceId);
    parentForm.setFieldValue(['bookmarks', name, 'resource_id'], newResourceId);
  };

  const handleStartDateChanged = (date: DatePickerProps['value']) => {
    parentForm.setFieldValue(['bookmarks', name, 'start_date'], date);
    setStartDate(date);
    let dateWithDefaultTime =
      date !== null
        ? dayjs(date as Dayjs | string)
            .set('hour', 9)
            .set('minute', 0)
        : date;
    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
    if (dateWithDefaultTime !== null && dateWithDefaultTime.isBefore(dayjs().endOf('day'))) {
      dateWithDefaultTime = dateWithDefaultTime.add(1, 'day');
    }
    parentForm.setFieldValue(['bookmarks', name, 'email_send_date'], dateWithDefaultTime);
    setEmailDate(dateWithDefaultTime);
  };

  const handleEmailSendDateChange = (date: DatePickerProps['value']) => {
    setEmailDate(date);
    parentForm.setFieldValue(['bookmarks', name, 'email_send_date'], date);
  };

  const handleEnableEmailDateChange = (checked: boolean) => {
    setEmailDateIsEnabled(checked);
    if (checked) {
      const startDate = parentForm.getFieldValue(['bookmarks', name, 'start_date']) as
        | Dayjs
        | string
        | null;
      let dateWithDefaultTime =
        startDate !== null ? dayjs(startDate).set('hour', 9).set('minute', 0) : startDate;
      // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
      if (dateWithDefaultTime !== null && dateWithDefaultTime.isBefore(dayjs().endOf('day'))) {
        dateWithDefaultTime = dateWithDefaultTime.add(1, 'day');
      }
      parentForm.setFieldValue(['bookmarks', name, 'email_send_date'], dateWithDefaultTime);
      setEmailDate(dateWithDefaultTime);
    } else {
      parentForm.setFieldValue(['bookmarks', name, 'email_send_date'], null);
      setEmailDate(null);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div style={{ width: '100%' }}>
        <Space direction="horizontal">
          <Item
            label="Start date"
            name={[name, 'start_date']}
          >
            <DatePicker
              disabledDate={date => date.isBefore(dayjs().subtract(1, 'day').endOf('day'))}
              onChange={handleStartDateChanged}
            />
          </Item>

          <Item
            label="End date"
            name={[name, 'end_date']}
          >
            <DatePicker
              disabledDate={date =>
                (startDate !== null && startDate !== undefined
                  ? date.isBefore(startDate.endOf('day'))
                  : false) || date.isBefore(dayjs().endOf('day'))
              }
            />
          </Item>
        </Space>
        <Space direction="horizontal">
          <Item
            label="Date to send email"
            name={[name, 'email_send_date']}
          >
            <Switch
              checked={emailDateIsEnabled}
              onChange={handleEnableEmailDateChange}
              style={{ marginRight: '1rem' }}
            />
            {emailDateIsEnabled && (
              <DatePicker
                disabled={disableEmailDate}
                disabledDate={date =>
                  (startDate !== null && startDate !== undefined
                    ? date.isBefore(startDate.startOf('day'))
                    : false) || date.isBefore(dayjs().endOf('day'))
                }
                format="YYYY-MM-DD HH:mm"
                onChange={handleEmailSendDateChange}
                showTime={{ format: 'HH:mm' }}
                value={emailDate}
              />
            )}
          </Item>
        </Space>

        <Item
          label="Resource type"
          name={[name, 'resource_type']}
        >
          <Select<'Course' | 'CourseCollection'>
            onChange={resourceType => {
              parentForm.setFieldValue(['bookmarks', name, 'resource_id'], undefined);
              setResourceId(undefined);
              dispatch({
                resource_type: resourceType,
                type: 'UPDATE_RESOURCE_TYPE'
              });
            }}
          >
            <Select.Option value="CourseCollection">Collection</Select.Option>
            <Select.Option value="Course">Course</Select.Option>
          </Select>
        </Item>

        <Item
          label="Resource"
          name={[name, 'resource_id']}
        >
          <Select
            allowClear
            disabled={!state.resourceType}
            filterOption={() => true}
            notFoundContent={state.reloading ? <Spin size="small" /> : undefined}
            onChange={updateResourceIdHandler}
            onSearch={name => setCurNameValue(name)}
            showSearch
          >
            {state.optionsData.map(({ name, value }, index) => (
              <Select.Option
                key={index}
                value={value}
              >
                {name}
              </Select.Option>
            ))}
          </Select>
        </Item>
        {resourceId !== undefined && (
          <Item>
            <DataTable
              columns={subBookmarkColumns}
              getMethod={getBookmarks}
              getParams={{
                assigned_by_id: assignedById,
                resource_id: resourceId
              }}
            />
          </Item>
        )}
      </div>

      {remove && (
        <DeleteOutlined
          onClick={() => remove(name)}
          style={{ fontSize: '15px', marginLeft: '10px' }}
        />
      )}
    </div>
  );
};

type BookmarkAssignmentFormProps = {
  assignedById: string;
  name: number;
  remove?: (index: number[] | number) => void;
} & (
  | {
      page: 'create';
      parentForm: FormInstance<{
        bookmarks: (
          | {
              email_send_date?: DatePickerProps['value'] | null;
              end_date?: DatePickerProps['value'] | null;
              resource_id: string | undefined;
              resource_type: 'Course' | 'CourseCollection';
              start_date?: DatePickerProps['value'] | null;
            }
          | undefined
        )[];
      }>;
    }
  | {
      page: 'edit';
      parentForm: FormInstance<{
        bookmarks?: {
          assigned_by_id: string;
          assigned_by_type: 'Network' | 'Organization';
          created_by_id: string | null;
          email_send_date: DatePickerProps['value'] | null;
          end_date: DatePickerProps['value'] | null;
          id: string;
          organization_id: string | null;
          resource:
            | {
                author: string;
                bipoc_communities: boolean;
                ce_credits: string;
                created_at: string;
                full_title: string;
                hours: string;
                id: string;
                lgbq_communities: boolean;
                link: string;
                other_communities: boolean;
                published: boolean;
                scormcloud_id: string | null;
                short_title: string;
                tgnc_communities: boolean;
                updated_at: string;
                year: number;
              }
            | {
                communities: ScoreType[];
                created_at: string;
                credits: string;
                description: string;
                external_accreditation_references: {
                  id: string;
                  vendor_name: VendorNames;
                  vendor_resource_id: string;
                }[];
                hours: string;
                id: string;
                learning_objectives: string[];
                level: 'advanced' | 'beginner' | 'foundation' | 'intermediate';
                name: string;
                published: 'not_published' | 'pinned' | 'published';
                specialties: string;
                updated_at: string;
              };
          resource_id: string;
          resource_type: 'Course' | 'CourseCollection';
          start_date: DatePickerProps['value'] | null;
          user_id: string | null;
        }[];
      }>;
    }
);

const { Item } = Form;

const nestedBookmarksColumns: ColumnsType<GetBookmarksResponse> = [
  {
    dataIndex: 'start_date',
    key: 'start_date',
    render: (value: GetBookmarksResponse['start_date']) => (
      <DateFormatter
        format="MM/DD/YYYY"
        value={value!}
      />
    ),
    title: 'Start date'
  },
  {
    dataIndex: 'end_date',
    key: 'end_date',
    render: (value: GetBookmarksResponse['end_date']) => (
      <DateFormatter
        format="MM/DD/YYYY"
        value={value!}
      />
    ),
    title: 'End date'
  },
  {
    dataIndex: 'email_send_date',
    key: 'email_send_date',
    render: (value: GetBookmarksResponse['email_send_date']) => (
      <DateFormatter
        format="MM/DD/YYYY HH:mm"
        value={value ?? ''}
      />
    ),
    title: 'Email send date'
  }
];
