import { Card } from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
  getCourseActivities,
  GetCourseActivitiesResponse,
  getCourseRegistration,
  GetCourseRegistrationResponse,
  useFetch
} from 'api';
import { CardEntry, CardHeader } from 'components/cards';
import { CardEntryList } from 'components/cards/card_entry_list';
import { UserLink } from 'components/common';
import { DateFormatter } from 'components/date_formatter';
import { SecondsFormatter } from 'components/seconds_formatter';
import { DataTable } from 'components/tables';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom-v5-compat';
import { coursePath } from 'utils/paths';

const educationActivitiesColumns: ColumnsType<GetCourseActivitiesResponse> = [
  {
    dataIndex: 'id',
    key: 'id',
    title: 'ID'
  },
  {
    dataIndex: 'payload',
    key: 'payload',
    title: 'Payload'
  },
  {
    dataIndex: 'created_at',
    key: 'created_at',
    render: (value: GetCourseActivitiesResponse['created_at']) => <DateFormatter value={value} />,
    title: <h1> Created at </h1>
  }
];

export const CourseRegistrationPage = () => {
  const { id } = useParams<{ id: string }>();
  const { data } = useFetch(getCourseRegistration, [id]);

  return (
    <div className="site-card-border-less-wrapper">
      {data?.data && (
        <Card
          headStyle={{ fontWeight: 'bold' }}
          style={{ minHeight: 'calc(100vh - 111px)' }}
          title={
            <CardHeader
              title={
                <>
                  Registration for {data.data.course.short_title} by{' '}
                  <UserLink user={data.data.user} />
                </>
              }
            />
          }
        >
          <CardEntry title="User">
            <UserLink user={data.data.user} />
          </CardEntry>
          <CardEntry title="Course">
            <Link to={coursePath(data.data.course.id)}>{data.data.course.short_title}</Link>
          </CardEntry>

          <CardEntryList
            fieldsMap={[
              { key: 'id', title: 'ID' },
              { key: 'completed_at', title: 'Completed at', type: 'date' },
              {
                key: 'total_seconds_tracked',
                render: (totalSeconds: GetCourseRegistrationResponse['total_seconds_tracked']) => (
                  <SecondsFormatter totalSeconds={totalSeconds} />
                ),
                title: 'Time tracked'
              },
              { key: 'created_at', title: 'Created at', type: 'date' },
              { key: 'updated_at', title: 'Updated at', type: 'date' }
            ]}
            values={data.data}
          />

          <DataTable
            columns={educationActivitiesColumns}
            getMethod={getCourseActivities}
            getParams={{ course_registration_id: id }}
            perPage={20}
          />
        </Card>
      )}
    </div>
  );
};
