import { Popconfirm } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { deleteNetwork, GetNetworksResponse } from 'api';
import { ActionsColumn } from 'components/tables';
import { Link } from 'react-router-dom-v5-compat';
import * as paths from 'utils/paths';

import { NetworkAnalyticsPreview } from './network_analytics_preview';

export const networkColumns: ColumnsType<GetNetworksResponse> = [
  {
    dataIndex: 'name',
    key: 'name',
    render: (value: GetNetworksResponse['name'], record) => (
      <Link to={paths.networkPath(record.id)}>{value}</Link>
    ),
    title: <h1> Network name </h1>
  },
  {
    dataIndex: 'analytics',
    key: 'analytics',
    render: (_value, record) => <NetworkAnalyticsPreview networkId={record.id} />,
    title: (
      <table>
        <thead>
          <th colSpan={3}>
            <h1 style={{ marginTop: '0' }}> Analytics </h1>
          </th>
        </thead>
        <tbody>
          <tr>
            <td width="33%">Total organization members</td>
            <td width="33%">Total providers</td>
            <td width="33%">Total providers</td>
          </tr>
        </tbody>
      </table>
    )
  },
  {
    render: (_value, record) => (
      <ActionsColumn
        customItems={[
          {
            key: 'delete',
            label: (
              <Popconfirm
                cancelText="Cancel"
                okText="Yes"
                onConfirm={() => deleteNetwork(record.id).then(() => window.location.reload())}
                title="Are you sure you want to delete this network?"
              >
                <span style={{ color: '#ff4d4f' }}>Delete</span>
              </Popconfirm>
            )
          }
        ]}
        editPath={paths.networkEditPath(record.id)}
        record={record}
      />
    ),
    title: 'Actions',
    width: '60px'
  }
];
