import { UploadOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Card,
  DatePicker,
  DatePickerProps,
  Divider,
  Form,
  FormProps,
  Space,
  Upload
} from 'antd';
import { UploadState } from 'antd/es/upload/interface';
import { getOrganizations, importUsersCsvFile } from 'api';
import { CardHeader } from 'components/cards';
import { InputForm, SearchInputItem, SwitchItem } from 'components/forms';
import { useState } from 'react';
import { normalizeFileFromUpload, toFormData } from 'utils/forms';

const { Item } = Form;

export const UsersImportCsvPage = () => {
  const [response, setResponse] = useState<ImportResponse | null>(null);
  const [showSendInviteEmailsInput, setShowSendInviteEmailsInput] = useState(true);

  const onFinish: FormProps<{
    delayed_invite_date?: DatePickerProps['value'];
    file: UploadState['fileList'];
    organization_id: {
      disabled: undefined;
      key: string;
      label: string;
      title: undefined;
      value: string;
    };
    send_invite_emails: boolean;
  }>['onFinish'] = values => {
    const { delayed_invite_date, file, organization_id, send_invite_emails } = values;

    const data = toFormData({
      delayed_invite_date: delayed_invite_date?.format('YYYY-MM-DDTHH:mm:ss[Z]'),
      file: file[0].originFileObj,
      organization_id: organization_id.value,
      send_invite_emails
    });

    setResponse(null);
    return importUsersCsvFile(data)
      .then(result => {
        setResponse(result);
      })
      .catch(() => {});
  };

  const initialData = {
    send_invite_emails: true
  };

  return (
    <Card title={<CardHeader title="Import CSV file" />}>
      <h1>Column headers should be:</h1>
      <p>
        First Name, Last Name, Email, Phone number, Employee ID, NPI, License Tier, Member role, Is
        clinical, Create Hubspot contact
      </p>

      <InputForm
        initialValues={initialData}
        name="import-csv"
        onFinish={onFinish}
      >
        <Alert
          description={
            <span>
              If number of users added to a license tier exceeds available licenses count for the
              organization of this license type a new cohort will be created automatically with size
              enough to provide licenses to all users
            </span>
          }
          message="A reminder!"
          style={{ marginBottom: '20px' }}
          type="warning"
        />

        <SearchInputItem
          getMethod={getOrganizations}
          getMethodArgs={[1, 20]}
          label="Organization"
          name="organization_id"
          paramsMethod={obj => ({
            key: obj.id,
            name: obj.name,
            value: obj.id
          })}
          required
          selectProps={{
            labelInValue: true
          }}
        />

        <Item
          getValueFromEvent={normalizeFileFromUpload}
          label="CSV file"
          name="file"
          required
          rules={[
            {
              validator: async (_, fileList: UploadState['fileList']) => {
                if (fileList.length < 1) {
                  return Promise.reject(new Error('Upload a file'));
                }
                return true;
              }
            }
          ]}
          valuePropName="fileList"
        >
          <Upload
            beforeUpload={() => false}
            maxCount={1}
            showUploadList={{
              showRemoveIcon: false
            }}
          >
            <Button icon={<UploadOutlined />}>Upload CSV file</Button>
          </Upload>
        </Item>

        <Divider />

        <SwitchItem
          label="Send invite email series"
          name="send_invite_emails"
          switchProps={{
            onChange: checked => {
              setShowSendInviteEmailsInput(checked);
            }
          }}
          tooltip="If this input is unchecked then all invite emails (including reminders) that would be sent upon each user creation will be suspended"
        />

        {showSendInviteEmailsInput && (
          <Item
            label="Delayed invite date"
            name="delayed_invite_date"
          >
            <DatePicker showTime />
          </Item>
        )}
      </InputForm>

      {response && (
        <Space
          direction="vertical"
          style={{ display: 'flex', margin: '50px' }}
        >
          <Alert
            message={<b>{response.status}</b>}
            type="info"
          />

          {response.info.map((info, i) => (
            <Alert
              key={i}
              description={info}
              type="info"
            />
          ))}

          {response.errors.map((error, i) => (
            <Alert
              key={i}
              description={error.message}
              message={`Line ${error.line_number}`}
              type="error"
            />
          ))}
        </Space>
      )}
    </Card>
  );
};
