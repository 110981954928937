import { ColumnsType } from 'antd/es/table';
import {
  GetCommunicationInfosOverviewResponse,
  GetLicenseCohortsResponse,
  GetOrganizationLicenseTiersResponse,
  GetOrganizationResponse
} from 'api';
import { DateFormatter } from 'components/date_formatter';

export const communicationInfoOverviewColumns: ColumnsType<
  GetCommunicationInfosOverviewResponse & { key: string }
> = [
  {
    dataIndex: 'email_type',
    key: 'email_type',
    title: <h1> E-mail type </h1>
  },
  {
    dataIndex: 'sent',
    key: 'sent',
    title: <h1> Number sent </h1>
  },
  {
    dataIndex: 'delivered',
    key: 'delivered',
    title: <h1> Number delivered </h1>
  },
  {
    dataIndex: 'opened',
    key: 'opened',
    title: <h1> Number opened </h1>
  },
  {
    dataIndex: 'clicked',
    key: 'clicked',
    title: <h1> Number clicked </h1>
  },
  {
    key: 'clicked_percentage',
    render: (_value, record) => {
      if (record.sent === 0) return '-';
      return `${Math.round((record.clicked / record.sent) * 100)}%`;
    },
    title: <h1> Clicked % </h1>
  }
];

export const licenseCohortColumns: ColumnsType<GetLicenseCohortsResponse> = [
  {
    key: 'license_tier_name',
    render: (_value, record) => record.organization_license_tier.license_tier.name,
    title: <h1> License Tier </h1>
  },
  {
    dataIndex: 'users_count',
    key: 'users_count',
    title: <h1> Users count </h1>
  },
  {
    dataIndex: 'start_date',
    key: 'start_date',
    render: (value: GetLicenseCohortsResponse['start_date']) => <DateFormatter value={value} />,
    title: <h1> Start date </h1>
  }
];

export const organizationOverviewColumns: ColumnsType<
  GetOrganizationResponse['detailed_overview'][0]
> = [
  {
    dataIndex: 'invite_date',
    key: 'invite_date',
    render: (value: GetOrganizationResponse['detailed_overview'][0]['invite_date']) => (
      <DateFormatter value={value} />
    ),
    title: <h1> Invite Date </h1>
  },
  {
    dataIndex: 'invited_users',
    key: 'invited_users',
    title: <h1> Invited Users </h1>
  },
  {
    dataIndex: 'active_count',
    key: 'active_count',
    title: <h1> Active Users </h1>
  },
  {
    dataIndex: 'completed_onboarding',
    key: 'completed_onboarding',
    title: <h1> Onboarded Users </h1>
  },
  {
    key: 'onboarded_percentage',
    render: (_value, record) => {
      if (record.invited_users === 0) return '-';
      return `${Math.round((record.completed_onboarding / record.invited_users) * 100)}%`;
    },
    title: <h1> Onboarded % </h1>
  }
];

export const orgLicenseColumns: ColumnsType<GetOrganizationLicenseTiersResponse> = [
  {
    key: 'license_tier_name',
    render: (_value, record) => record.license_tier.name,
    title: <h1> License Tier </h1>
  },
  {
    dataIndex: 'total_licenses',
    key: 'total_licenses',
    title: <h1> Total licenses </h1>
  },
  {
    dataIndex: 'available_licenses',
    key: 'available_licenses',
    title: <h1> Available licenses </h1>
  }
];
